import React from 'react'

class TextInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            placeHolderClass: 'text-12 pt-4 text-gray-light',
            bottomBorderClass: 'w-0',
            containerBorder: 'border-b',
            value: ''
        }
        this.inputRef = React.createRef()
        this.bottomBorderRef = React.createRef()
        this.changeValue = this.changeValue.bind(this)
        this.inputFocused = this.inputFocused.bind(this)
        this.inputBlured = this.inputBlured.bind(this)
        this.placeHolderClicked = this.placeHolderClicked.bind(this)
    }
    changeValue(e) {
        this.setState({ value: e.target.value })
    }
    inputFocused() {
        this.setState({ placeHolderClass: 'pt-1 text-10 text-violet', bottomBorderClass: 'w-full', containerBorder: '' })
    }
    inputBlured() {
        if (!this.state.value) {
            this.setState({ placeHolderClass: 'text-12 pt-4 text-gray-light', bottomBorderClass: 'w-0', containerBorder: 'border-b' })
        }
    }
    placeHolderClicked() {
        this.inputRef.current.focus()
    }
    render() {
        return (
            <div className={`bg-gray-lighter relative ${this.state.containerBorder}`}>
                <p
                    onClick={this.placeHolderClicked}
                    className={`absolute px-4 ${this.state.placeHolderClass}`}
                    style={{ transition: 'font-size 0.6s cubic-bezier(0.16, 1, 0.3, 1), padding 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}>{this.props.placeholder}</p>
                <input
                    id='input'
                    ref={this.inputRef}
                    value={this.state.value}
                    onChange={this.changeValue}
                    onFocus={this.inputFocused}
                    onBlur={this.inputBlured}
                    className={`w-full focus:outline-none bg-gray-lighter pb-1 pt-4 px-4 text-14 font-semibold text-purple-base`} />
                <div
                    className={`bg-gradient-to-r from-sky-blue via-purple-bg to-violet h-2px ${this.state.bottomBorderClass}`}
                    style={{ transition: 'width 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}></div>
            </div>
        )
    }
}

export default TextInput