function Magic() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8716 3.4732C14.8306 3.4732 15.6082 2.69577 15.6082 1.7366C15.6082 0.777433 14.8306 0 13.8716 0C12.9124 0 12.135 0.777433 12.135 1.7366C12.135 2.69577 12.9124 3.4732 13.8716 3.4732ZM0 21.5341L2.34909 23.8831L10.8914 15.3407L8.54234 12.9916L0 21.5341ZM22.7811 7.77683C22.7811 8.61101 22.1051 9.28703 21.2709 9.28703C20.4369 9.28703 19.7609 8.61101 19.7609 7.77683C19.7609 6.94294 20.4369 6.26677 21.2709 6.26677C22.1051 6.26677 22.7811 6.94294 22.7811 7.77683ZM21.5731 19.7821C22.5321 19.7821 23.3097 19.0047 23.3097 18.0455C23.3097 17.0863 22.5321 16.3089 21.5731 16.3089C20.6138 16.3089 19.8363 17.0863 19.8363 18.0455C19.8363 19.0047 20.6138 19.7821 21.5731 19.7821Z" fill="#B5C1CD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1094 11.0271L19.5428 15.6279L13.8025 15.5546L10.488 20.2413L8.78428 14.7596L3.30243 13.0557L7.98923 9.74122L7.91584 4.00092L12.5166 7.4343L17.953 5.59088L16.1094 11.0271Z" fill="#8397AB" />
            </svg>
        </div>
    )
}

export default Magic