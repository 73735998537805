function Invoice() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79999 0H22C23.1046 0 24 0.895431 24 2V2.4H4.79999V0ZM4.79999 4.8H24V6H4.79999V4.8ZM4.79999 8.4V24L7.19999 21.6L9.59999 24L12 21.6L14.4 24L16.8 21.6L19.2 24L21.6 21.6L24 24V8.4H4.79999Z" fill="#B5C1CD" />
                <path d="M0 4.8C0 2.14903 2.14903 0 4.8 0V12H0V4.8Z" fill="#8397AB" />
            </svg>
        </div>
    )
}

export default Invoice