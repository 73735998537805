import '../../assets/css/animate.css';
function DashboardDropdown(props) {
    return (
        <div className='p-6 rounded-lg bg-gray-lighter '>
            <svg
                className={`mx-auto ${props.isActive ? 'rotateCustom' : ''}`}
                style={{ transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 1.112L14.8752 0L7.9896 6.6088L7.2552 5.904L7.2592 5.908L1.1416 0.036L0 1.1312C1.6904 2.7544 6.4112 7.2856 7.9896 8.8C9.1624 7.6752 8.0192 8.772 16 1.112Z" fill="#8397AB" />
            </svg>
        </div>

    )
}

export default DashboardDropdown;