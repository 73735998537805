import React from 'react'
class Slider extends React.Component {
    constructor(props) {
        super(props)
        this.state = { noOfIndicators: [1, 2, 3, 4, 5], active: 2 }
        this.changeActive = this.changeActive.bind(this)
    }
    changeActive(indicator) {
        this.setState({ active: indicator })
    }
    render() {
        return (
            <div className={` grid gap-x-2 grid-cols-5`}>
                {this.state.noOfIndicators.map((indicator) => {
                    return (
                        <div onClick={this.changeActive.bind(this, indicator)} className={`bg-white opacity-40 h-1 rounded-md cursor-pointer transition-all duration-500 linear ${this.state.active === indicator ? 'opacity-100' : ''}`}>
                        </div>
                    )
                })}
                {/* <div key="index" v-for="(indicator, index) in noOfIndicators">
              <div
                ref="indicator"
                @click="changeIndicator(index)"
                class="bg-smoke-200 h-2 rounded-md cursor-pointer transition-all duration-500 linear"
              ></div> */}
            </div>
        )
    }
}

export default Slider