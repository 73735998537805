import React from 'react'
import SubList from './SubList'
import ListIcon from '../icons/ListIcon'

class List extends React.Component {
    constructor(props) {
        super(props)
        this.state = { containerClass: 'shadow-options', listClass: 'h-0', active: false }
        this.toogleList = this.toogleList.bind(this)
    }
    toogleList() {
        if (this.props.isList) {
            this.setState(function (state) {
                return { active: !state.active, listClass: state.active ? 'h-0' : 'h-130', containerClass: state.active ? 'shadow-options' : 'border border-violet' }
            })
        } else {
            this.setState(function (state) {
                return { active: !state.active, containerClass: state.active ? 'shadow-options' : 'border border-violet' }
            })
        }
    }
    render() {
        return (
            <div
                onClick={this.toogleList}
                className={`option px-4 py-6 rounded-lg cursor-pointer ${this.state.containerClass}`}
                style={{ transition: 'border 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}
            >
                <div className='flex items-center space-x-3'>
                    <ListIcon active={this.state.active}></ListIcon>
                    <span className='text-16 font-semibold'>{this.props.title}</span>
                </div>
                { this.props.isList
                    &&
                    <div
                        className={`content overflow-hidden ${this.state.listClass}`}
                        style={{ transition: 'height 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}>
                        <div className='subtext flex space-x-3 pb-4'>
                            <div className='opacity-0'>
                                <ListIcon ></ListIcon>
                            </div>
                            <span className='text-13 text-gray-light'>{this.props.subtext}</span>
                        </div>
                        <div className='space-y-2'>
                            <SubList text={this.props.list.a}></SubList>
                            <SubList text={this.props.list.b}></SubList>
                            <SubList text={this.props.list.c}></SubList>
                        </div>
                    </div>}
            </div>
        )
    }
}

export default List