import Chart from 'chart.js'

function lineChart(ref) {
    let ctx = ref.getContext('2d');
    let gradient = ctx.createLinearGradient(0, 150, 0, 0);

    // Add three color stops
    gradient.addColorStop(0, '#ffffff96');
    gradient.addColorStop(1, 'rgba(255, 99, 132, 0)');
    let myChart = new Chart(ctx, {
        type: 'line',
        data: {
            datasets: [{
                label: 'jjgj',
                data: [4, 8, 11, 13, 14, 8, 6],
                backgroundColor: gradient,
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                ],
                pointHoverBorderWidth: '5',
                pointHoverRadius: '5',
                pointHoverBackgroundColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }]
        },
        options: {
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            scales: {
                yAxes: [{
                    type: 'category',
                    labels: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
                    gridLines: {
                        display: true,
                        color: '#EEEFF7',
                        drawBorder: false,
                    },
                    ticks: {
                        fontFamily: 'BR Firma',
                        fontColor: '#8397AB',
                        padding: 20,
                        maxTicksLimit: 4,
                        callback: function (value, index, values) {
                            return value + 'k';
                        },

                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: false
                    },
                    offset: true,
                    type: 'category',
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontFamily: 'BR Firma',
                        fontColor: '#8397AB',
                        padding: 20,
                    },
                    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG']
                }]
            }
        }
    });
}

export default lineChart