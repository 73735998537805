function currentCard() {
    return (
        <div>
            <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="44.3793" height="44" rx="20" fill="#7E51FF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0655 13.2H13.3138C12.0823 13.2 11.1059 14.179 11.1059 15.4L11.0948 28.6C11.0948 29.821 12.0823 30.8 13.3138 30.8H31.0655C32.2971 30.8 33.2845 29.821 33.2845 28.6V15.4C33.2845 14.179 32.2971 13.2 31.0655 13.2ZM31.0655 28.6H13.3138V22H31.0655V28.6ZM13.3138 17.6H31.0655V15.4H13.3138V17.6Z" fill="#7E51FF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7384 15.3149H19.1316C19.1152 15.2584 19.1053 15.1986 19.1028 15.1365H19.7982C19.7841 15.1963 19.7645 15.2559 19.7384 15.3149Z" fill="#BCBCB7" />
            </svg>
        </div>
    )
}

export default currentCard