function savingsCard() {
    return (
        <div>
            <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" x="0.413818" width="44.3793" height="44" rx="20" fill="#00D2FF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4792 13.2H13.7275C12.496 13.2 11.5196 14.179 11.5196 15.4L11.5085 28.6C11.5085 29.821 12.496 30.8 13.7275 30.8H31.4792C32.7108 30.8 33.6982 29.821 33.6982 28.6V15.4C33.6982 14.179 32.7108 13.2 31.4792 13.2ZM31.4792 28.6H13.7275V22H31.4792V28.6ZM13.7275 17.6H31.4792V15.4H13.7275V17.6Z" fill="#00D2FF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1522 15.3149H19.5454C19.529 15.2584 19.5191 15.1986 19.5166 15.1365H20.212C20.1979 15.1963 20.1783 15.2559 20.1522 15.3149Z" fill="#BCBCB7" />
            </svg>
        </div>
    )
}

export default savingsCard