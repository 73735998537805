import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from './components/Login'
import SignUp from './components/SignUp'
import Dashboard from './components/Dashboard'
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/login'>
            <Login />
          </Route>
          <Route path='/dashboard'>
            <Dashboard />
          </Route>
          <Route path='/'>
            <SignUp />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
