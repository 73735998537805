function BankFees() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#E900AE" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 8.33333L11.6667 5L18.3333 8.33333V9.66667H5V8.33333ZM6.33333 11H8.33333V15.6667H6.33333V11ZM10.6667 15.6667V11H12.6667V15.6667H10.6667ZM18.3333 19V17H5V19H18.3333ZM15 11H17V15.6667H15V11Z" fill="white" />
            </svg>

        </div>
    )
}

export default BankFees