import React from 'react'
class SubListIcon extends React.Component {
    render() {
        return (
            <div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.12" cx="8" cy="8" r="8" fill="#4CD964" />
                    <path d="M6.33482 10.3117L3.84713 7.82401L3 8.66518L6.33482 12L13.4937 4.84116L12.6525 4L6.33482 10.3117Z" fill="#4CD964" />
                </svg>

            </div>
        )
    }
}
export default SubListIcon