import {
    Link
} from "react-router-dom";
import BackButton from '../../icons/BackButton';

function TopBar(props) {
    return (
        <div className='top-menu grid grid-cols-12 py-10 items-center'>
            <div onClick={props.prevStep} className='col-span-2 cursor-pointer'>
                {props.showStepB && <BackButton></BackButton>}
            </div>
            <div className='fadeInA col-end-13 col-span-8 sm:col-end-13 sm:col-span-6 lg:col-end-13 lg:col-span-4 xl:col-end-13  xl:col-span-3 justify-self-end'>
                <p className='text-14 font-semibold'>Already a member? <Link className='text-violet' to={`/${props.where}`}>{props.text}</Link></p>
            </div>
        </div>
    )
}

export default TopBar