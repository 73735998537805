import '../../assets/css/animate.css';
function DashboardDropdown(props) {
    return (
        <div className='p-6 rounded-lg bg-gray-lighter '>
            <svg
                className={`mx-auto ${props.isActive ? 'rotateCustom' : ''}`}
                style={{ transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}
                width="10"
                height="17"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.28799 16.4L9.39999 15.2752L2.79119 8.3896L3.49599 7.6552L3.49199 7.6592L9.36399 1.5416L8.26879 0.4C6.64559 2.0904 2.11439 6.8112 0.599993 8.3896C1.72479 9.5624 0.627994 8.4192 8.28799 16.4" fill="#8397AB" />
            </svg>
        </div>

    )
}

export default DashboardDropdown;