function BackButton() {
    return (
        <div className='p-2 rounded-lg border max-w-40'>
            <svg className='mx-auto' width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.688 16L8.8 14.8752L2.1912 7.9896L2.896 7.2552L2.892 7.2592L8.764 1.1416L7.6688 0C6.0456 1.6904 1.5144 6.4112 0 7.9896C1.1248 9.1624 0.0280004 8.0192 7.688 16Z" fill="#8397AB" />
            </svg>
        </div>

    )
}

export default BackButton;