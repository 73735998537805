import ListIcon from '../icons/ListIcon'
import SubListItem from '../icons/SubListItem'

function SubList(props) {
    return (
        <div className='subtext-list flex space-x-3'>
            <div className='opacity-0'>
                <ListIcon ></ListIcon>
            </div>
            <div className='flex space-x-3'>
                <SubListItem></SubListItem>
                <span className='text-13 text-gray-light'>{props.text}</span>
            </div>
        </div>
    )
}

export default SubList