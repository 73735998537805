import React from 'react'

class Dropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showList: false, dropdownList: 'h-0 opacity-0' }
        this.toogleDropdown = this.toogleDropdown.bind(this)
    }
    toogleDropdown() {
        this.setState(function (state) { return { showList: !state.showList } })
        this.setState(function (state) {
            return {
                dropdownList: state.showList ? 'h-full opacity-100' : 'h-0 opacity-0'
            }
        })
    }
    render() {
        return (
            <div className='relative'>
                <div
                    onClick={this.toogleDropdown}
                    className='bg-gray-lighter relative border-b max-h-44 pb-1 px-3 flex items-center'>
                    <div>
                        <span className='text-10 text-gray-light'>Country</span>
                        <p className='text-14'>+233</p>
                    </div>
                    <svg className='ml-auto' width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.705 1.705L11.295 0.294998L6.705 4.875L2.115 0.294998L0.705002 1.705L6.705 7.705L12.705 1.705Z" fill="#1C1335" />
                    </svg>
                </div>
                <div
                    className={`bg-gray-lighter text-right p-2 absolute w-full z-10 ${this.state.dropdownList} `}
                    style={{ transition: 'height 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}>
                    <ul className='divide-y text-10 text-gray-light'>
                        <li>+233</li>
                        <li>+234</li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default Dropdown