function MarketingIcon() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#C155FF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.429 4.46875C14.429 4.20966 14.6387 4 14.8978 4C15.1569 4 15.3665 4.20966 15.3665 4.46875V5.40625C15.3665 5.66535 15.1569 5.875 14.8978 5.875C14.6387 5.875 14.429 5.66535 14.429 5.40625V4.46875ZM18.6542 10.6431L12.4139 4.40191C12.0459 4.03478 11.4506 4.03913 11.0873 4.40144C10.722 4.76766 10.722 5.36228 11.0873 5.72803L17.3285 11.9688C17.6941 12.3344 18.2885 12.3344 18.6542 11.9688C19.0208 11.6021 19.0208 11.0096 18.6542 10.6431ZM10.6244 6.59079L10.5774 6.82623C10.2465 8.48117 9.44248 10.0534 8.35495 11.3277L11.7502 14.7229C13.0243 13.6356 14.5749 12.8099 16.2299 12.4787L16.4657 12.4317L10.6244 6.59079ZM7.73248 12.0309L5.41207 14.3509C4.86379 14.8991 4.8615 15.7893 5.41207 16.3398L6.73776 17.6655C7.28604 18.2138 8.17617 18.2161 8.72673 17.6655L11.0469 15.3453L7.73248 12.0309ZM8.06392 15.677C7.88082 15.8601 7.58417 15.8601 7.40107 15.677C7.21798 15.4939 7.21798 15.1973 7.40107 15.0142L8.06392 14.3513C8.24701 14.1682 8.54367 14.1682 8.72676 14.3513C8.90986 14.5344 8.90986 14.8311 8.72676 15.0142L8.06392 15.677ZM13.0041 17.915L13.6417 17.2773C14.1923 16.7268 14.1905 15.8367 13.6418 15.2888L13.1681 14.8149C12.9064 14.9907 12.6564 15.1818 12.4133 15.3856L12.9789 15.9521C13.1616 16.1338 13.1629 16.4296 12.9789 16.6145L12.3307 17.2627L11.3782 16.3398L10.0524 17.6655L12.1792 19.7264C12.5421 20.0903 13.1381 20.0923 13.5044 19.7259C13.8702 19.3602 13.8702 18.7655 13.5044 18.3997L13.0041 17.915ZM18.6478 7.75001H17.7103C17.4512 7.75001 17.2415 7.95966 17.2415 8.21876C17.2415 8.47785 17.4512 8.68751 17.7103 8.68751H18.6478C18.9069 8.68751 19.1165 8.47785 19.1165 8.21876C19.1165 7.95966 18.9069 7.75001 18.6478 7.75001ZM17.3788 5.07481C17.5619 4.89172 17.8586 4.89172 18.0417 5.07481C18.2248 5.25791 18.2248 5.55457 18.0417 5.73766L17.1042 6.67516C16.9211 6.85829 16.6244 6.85826 16.4413 6.67516C16.2582 6.49207 16.2582 6.19541 16.4413 6.01232L17.3788 5.07481Z" fill="white" />
            </svg>

        </div>
    )
}

export default MarketingIcon