import React from 'react'
class ListIcon extends React.Component {
    render() {
        return (
            <div>
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle style={{ transition: 'fill 0.6s cubic-bezier(0.16, 1, 0.3, 1), stroke 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }} cx="8" cy="9" r="8" fill={this.props.active ? '#FA4A84' : 'white'} stroke={this.props.active ? '' : "#EEEFF7"} stroke-width="1.5" />
                </svg>
            </div>
        )
    }
}
export default ListIcon