function TransferIcon() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#00EC47" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 12C19 8.136 15.864 5 12 5C8.136 5 5 8.136 5 12C5 15.864 8.136 19 12 19C15.864 19 19 15.864 19 12ZM14.1 8.15L16.55 10.6L14.1 13.05V11.3H11.3V9.9H14.1V8.15ZM7.45 13.4L9.9 15.85V14.1H12.7V12.7H9.9V10.95L7.45 13.4Z" fill="white" />
            </svg>
        </div>
    )
}

export default TransferIcon