import React from 'react'
import TopBar from './layout/SignUp/TopBar'
import TextInput from './elements/TextInput'
import DashboardDropdown from './icons/DashboardDropdown'
import MobileDashboardDropdown from './icons/MobileDashboardDropdown'
import Invoice from './icons/invoice'
import Magic from './icons/Magic'
import Bell from './icons/Bell'
import CurrentCard from './icons/currentCard'
import SavingsCard from './icons/savingsCard'
import BankFees from './icons/bankFees'
import Internet from './icons/internet'
import MarketingIcon from './icons/marketingIcon'
import TransferIcon from './icons/transferIcon'
import avatar from '../assets/img/avatar.png'
import lineChart from '../helpers/lineChart'
import '../assets/css/animate.css';
class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = { dropdownListClass: 'h-0', mobileDropdownListClass: 'w-0 opacity-0', listActive: false, mobileListActive: false }
        this.toogleDropdownList = this.toogleDropdownList.bind(this)
        this.toogleMobileDropdownList = this.toogleMobileDropdownList.bind(this)
        this.canvasRef = React.createRef()

    }
    toogleDropdownList() {
        this.setState(function (state) {
            return {
                listActive: !state.listActive,
                dropdownListClass: state.listActive ? 'h-0' : 'h-210'
            }
        })
    }
    toogleMobileDropdownList() {
        this.setState(function (state) {
            return {
                mobileListActive: !state.mobileListActive,
                mobileDropdownListClass: state.mobileListActive ? 'w-0 opacity-0' : 'w-205 px-8 opacity-100'
            }
        })
    }
    componentDidMount() {
        lineChart(this.canvasRef.current)
    }
    render() {
        return (
            <div className="signup xl:grid grid-cols-12 h-screen">
                <div className="sidebar hidden xl:grid col-span-3 py-4 px-8 shadow-dashboard-sidebar z-10 h-screen">
                    <div className=''>
                        <div className='fadeInA relative'>
                            <div onClick={this.toogleDropdownList} className='profile py-3 grid grid-cols-12 items-center cursor-pointer'>
                                <div className='col-span-9 flex space-x-6 items-center'>
                                    <span className='text-12 font-normal p-4 bg-purple-base text-white rounded-full'>BN</span>
                                    <div className=' grid'>
                                        <span className='text-16 font-semibold text-purple-base'>Clayvant Inc</span>
                                        <span className='text-12 font-normal text-gray-light'>Manage account</span>
                                    </div>
                                </div>
                                <div className='col-span-3 mx-auto '>
                                    <DashboardDropdown isActive={this.state.listActive}></DashboardDropdown>
                                </div>
                            </div>
                            <div
                                className={`absolute w-full shadow-dashboard-profile-dropdown z-10 bg-white rounded-lg overflow-hidden ${this.state.dropdownListClass}`}
                                style={{ transition: 'height 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}>
                                <ul className=''>
                                    <li className='border-b px-8 py-4 text-purple-base'>Clayvant Inc</li>
                                    <li className='border-b px-8 py-4 text-gray-light'>Business name 2</li>
                                    <li className='px-8 py-4 text-gray-light'>Business name 3</li>
                                </ul>
                                <span className='px-8 text-11 text-violet'>Add a business</span>
                            </div>
                        </div>
                        <div className='fadeInB slider py-6 space-y-10'>
                            <div className='flex space-x-6'>
                                <Invoice></Invoice>
                                <p className='text-14'>Home</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Invoice></Invoice>
                                <p className='text-14'>Invoice</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Invoice></Invoice>
                                <p className='text-14'>Invoice</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Magic></Magic>
                                <p className='text-14'>Management</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Magic></Magic>
                                <p className='text-14'>Security</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Magic></Magic>
                                <p className='text-14'>Support</p>
                            </div>
                        </div>
                    </div>
                    <div className='fadeInC footer text-white text-14 self-end space-y-52'>
                        <h2 className='text-3xl font-bold text-gray-light'>prospa</h2>
                    </div>
                </div>
                <div className="main-content bg-gray-lighter col-span-9 overflow-auto">
                    {/** sidebar view for mobile */}
                    <div className='block xl:hidden bg-white px-4 py-3 shadow-dashboard-sidebar relative'>
                        <div className='fadeInA logo grid grid-cols-12 self-center'>
                            <div className='col-span-6 self-center'>
                                <h2 className='text-3xl font-semibold text-gray-light'>prospa</h2>
                            </div>
                            <div onClick={this.toogleMobileDropdownList} className='col-end-13 col-span-2 mx-auto '>
                                <MobileDashboardDropdown isActive={this.state.mobileListActive}></MobileDashboardDropdown>
                            </div>
                        </div>
                        <div
                            style={{ 'right': '0px', transition: 'width 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1)' }}
                            className={`absolute bg-white slider py-6 space-y-10 h-screen z-10 overflow-auto ${this.state.mobileDropdownListClass}`}>
                            <div className='flex space-x-6 items-center'>
                                <span className='text-xs p-2 bg-purple-base text-white rounded-full'>BN</span>
                                <div className='grid'>
                                    <span className='text-xs font-semibold text-purple-base'>Clayvant Inc</span>
                                </div>
                            </div>
                            <div className=''>
                                <div className='divide-y-2 text-xs'>
                                    <p>Clayvant Inc</p>
                                    <p className='text-gray-light'>Business name 2</p>
                                    <p className='text-gray-light'>Business name 3</p>
                                </div>
                                <span className='text-10 text-violet'>Add a business</span>
                            </div>
                            <div className='flex space-x-6'>
                                <Invoice></Invoice>
                                <p className='text-14'>Home</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Invoice></Invoice>
                                <p className='text-14'>Invoice</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Invoice></Invoice>
                                <p className='text-14'>Invoice</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Magic></Magic>
                                <p className='text-14'>Management</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Magic></Magic>
                                <p className='text-14'>Security</p>
                            </div>
                            <div className='flex space-x-6'>
                                <Magic></Magic>
                                <p className='text-14'>Support</p>
                            </div>
                        </div>
                    </div>
                    {/* main content continues px-4 sm:px-20 */}
                    <div className='overflow-hidden'>
                        <div className='fadeInA dashboard-head py-6 grid grid-cols-12 bg-white px-4 xl:px-20 items-center'>
                            <div className='col-span-2'>
                                <p className='text-21 text-gray-light font-semibold'>Dashboard</p>
                            </div>
                            <div className='col-start-8 col-span-5 sm:col-start-11 sm:col-span-2 flex justify-self-end items-center space-x-6'>
                                <div className='mx-auto'>
                                    <Bell></Bell>
                                </div>
                                <img src={avatar} />
                            </div>
                        </div>
                        <div className='fadeInB welcome-back px-4 xl:px-20 sm:grid grid-cols-12 items-center py-8 space-y-4 sm:space-y-0'>
                            <div className="col-span-7 lg:col-span-6 grid">
                                <span className='text-24 font-bold text-purple-base'>Welcome back, Kathy</span>
                                <span className='text-16 text-purple-base font-normal'>Here’s what has been happening in the last <span className='text-violet cursor-pointer'>7 days</span></span>
                            </div>
                            <div className='col-end-13 col-span-3'>
                                <button className='w-full bg-violet text-16 text-white rounded py-3 focus:outline-none'>Add a sub account</button>
                            </div>
                        </div>
                        <div className='stats px-4 xl:px-20 pb-2 space-y-8'>
                            <div className='top-records sm:grid grid-cols-12 gap-x-8 space-y-8 sm:space-y-0'>
                                <div className='fadeInC record col-span-6 p-6 pb-4 bg-white shadow-statistics space-y-12'>
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-9 lg:col-span-8 grid '>
                                            <span className='text-purple-base text-14 font-semibold'>CURRENT ACCOUNT</span>
                                            <span className='text-14 font-normal text-gray-light'>PROVIDUS BANK - 9906533917</span>
                                        </div>
                                        <div className='col-end-13 col-span-2 justify-self-end'>
                                            <div className='mx-auto'>
                                                <CurrentCard></CurrentCard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='balance'>
                                        <span className='text-36 text-purple-base'>N814,800.45</span>
                                    </div>
                                </div>
                                <div className='fadeInD record col-span-6 p-6 pb-4 bg-white shadow-statistics space-y-12'>
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-8 grid '>
                                            <span className='text-purple-base text-14 font-semibold'>SAVINGS ACCOUNT</span>
                                            <span className='text-14 font-normal text-gray-light'>SUB ACCOUNT - 12346789</span>
                                        </div>
                                        <div className='col-end-13 col-span-2 justify-self-end'>
                                            <div className='mx-auto'>
                                                <SavingsCard></SavingsCard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='balance'>
                                        <span className='text-36 text-purple-base'>N39,342.45</span>
                                    </div>
                                </div>
                            </div>
                            <div className='bottom-record lg:grid grid-cols-12 gap-x-8 space-y-8 lg:space-y-0'>
                                <div className='fadeInE record col-span-7 p-6 pb-4 bg-white shadow-statistics space-y-8'>
                                    <div className='header'>
                                        <span className='text-18 font-semibold text-purple-base'>June summary</span>
                                    </div>
                                    <div className='summary sm:flex sm:space-x-12 space-y-4 sm:space-y-0'>
                                        <div className='grid'>
                                            <span className='text-14 text-gray-light'>Money in</span>
                                            <span className='text-14 text-purple-base font-semibold'>N 5,650,000</span>
                                        </div>
                                        <div className='grid'>
                                            <span className='text-14 text-gray-light'>Money Out</span>
                                            <span className='text-14 text-purple-base font-semibold'>N 5,650,000</span>
                                        </div>
                                        <div className='grid'>
                                            <span className='text-14 text-gray-light'>Difference</span>
                                            <span className='text-14 text-purple-base font-semibold'>N 5,650,000</span>
                                        </div>
                                    </div>
                                    <canvas className='w-full' ref={this.canvasRef}></canvas>
                                </div>
                                <div className='fadeInF record col-span-5 p-6 pb-4 bg-white shadow-statistics space-y-8'>
                                    <div className='header'>
                                        <span className='text-18 font-semibold text-purple-base'>Cash outflow</span>
                                    </div>
                                    <div className='content space-y-10'>
                                        <div className='sm:grid grid-cols-12 space-y-2 sm:space-y-0'>
                                            <div className='col-span-5 flex space-x-5 items-center'>
                                                <div>
                                                    <BankFees></BankFees>
                                                </div>
                                                <span className='text-14 text-gray-light'>Bank Fees</span>
                                            </div>
                                            <div className='col-span-7 grid justify-items-start'>
                                                <span className='text-14 font-semibold text-purple-base'>- N 250,000</span>
                                                <div className='bg-gray-bar w-full h-1 rounded-lg'>
                                                    <div className='bg-yellow-deep w-11/12 h-1 rounded-lg'>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sm:grid grid-cols-12 space-y-2 sm:space-y-0'>
                                            <div className='col-span-5 flex space-x-5 items-center'>
                                                <div>
                                                    <Internet></Internet>
                                                </div>
                                                <span className='text-14 text-gray-light'>Internet</span>
                                            </div>
                                            <div className='col-span-7 grid justify-items-start'>
                                                <span className='text-14 font-semibold text-purple-base'>- N 250,000</span>
                                                <div className='bg-gray-bar w-full h-1 rounded-lg'>
                                                    <div className='bg-yellow-deep w-8/12 h-1 rounded-lg'>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sm:grid grid-cols-12 space-y-2 sm:space-y-0'>
                                            <div className='col-span-5 flex space-x-5 items-center'>
                                                <div>
                                                    <MarketingIcon></MarketingIcon>
                                                </div>
                                                <span className='text-14 text-gray-light'>Marketng</span>
                                            </div>
                                            <div className='col-span-7 grid justify-items-start'>
                                                <span className='text-14 font-semibold text-purple-base'>- N 250,000</span>
                                                <div className='bg-gray-bar w-full h-1 rounded-lg'>
                                                    <div className='bg-yellow-deep w-4/12 h-1 rounded-lg'>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sm:grid grid-cols-12 space-y-2 sm:space-y-0'>
                                            <div className='col-span-5 flex space-x-5 items-center'>
                                                <div>
                                                    <TransferIcon></TransferIcon>
                                                </div>
                                                <span className='text-14 text-gray-light'>Transfer</span>
                                            </div>
                                            <div className='col-span-7 grid justify-items-start'>
                                                <span className='text-14 font-semibold text-purple-base'>- N 250,000</span>
                                                <div className='bg-gray-bar w-full h-1 rounded-lg'>
                                                    <div className='bg-yellow-deep w-2/12 h-1 rounded-lg'>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='fadeInG transactions p-6 pb-4 bg-white shadow-statistics space-y-8'>
                                <div className='title grid grid-cols-12 items-center'>
                                    <div className='col-span-4'>
                                        <span className='text-16 font-semibold text-purple-base'>Recent transactions</span>
                                    </div>
                                    <div className='col-end-13 sm:col-end-13 col-span-4 sm:col-span-2 justify-self-end'>
                                        <button className='bg-white text-14 text-violet font-semibold rounded-lg border border-violet py-2 px-3 focus:outline-none'>See all</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Dashboard;
