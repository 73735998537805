import React from 'react'
import {
    Link
} from "react-router-dom";
import TopBar from './layout/SignUp/TopBar'
import Slider from './layout/Slider'
import TextInput from './elements/TextInput'
import List from './elements/List'
import Dropdown from './icons/Dropdown'
import '../assets/css/animate.css';
import safe from '../assets/img/safe.png'
class SignUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = { formClassName: 'opacity-1', formClassNameB: 'opacity-0', showStepA: true, showStepB: false }
        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
    }
    prevStep() {
        this.setState({ formClassNameB: 'transformCustomB opacity-0' })
        setTimeout(() => {
            this.setState({ showStepA: true, showStepB: false })
            this.setState({ formClassName: 'opacity-1' })
        }, 300)
    }
    nextStep() {
        this.setState({ formClassName: 'transformCustom opacity-0' })
        setTimeout(() => {
            this.setState({ showStepA: false, showStepB: true })
            this.setState({ formClassNameB: 'opacity-1' })
        }, 300)
    }
    render() {
        return (
            <div className="signup sm:grid grid-cols-12 h-screen">
                <div className="hidden sm:grid sidebar col-span-3 bg-purple-bg py-4 px-8 ">
                    <div className=''>
                        <div className='logo py-3 fadeInA'>
                            <h2 className='text-3xl font-semibold text-white'>prospa</h2>
                        </div>
                        <div className='slider py-6 fadeInB'>
                            <Slider></Slider>
                        </div>
                        <div className='subtext text-white fadeInC'>
                            <h2 className='font-semibold sm:text-xl lg:text-3xl xl:text-32'>Create multiple <br /> sub-account</h2>
                            <p className='sm:text-xs sm:font-extralight lg:text-normal xl:text-14 xl:font-light'>Organise your business finances easily with multiple accounts. No limits!</p>
                        </div>
                    </div>
                    <div className='footer text-white text-14 self-end space-y-52'>
                        <div className='fadeInD'>
                            <img className='mx-auto' src={safe} />
                        </div>
                        <p className='fadeInE'>© 2020 Prospa Inc</p>
                    </div>
                </div>
                <div className="main-content col-span-9 ">
                    {/** sidebar view for mobile */}
                    <div className='block sm:hidden bg-purple-bg px-4 py-3 space-y-4'>
                        <div className='fadeInA logo'>
                            <h2 className='text-3xl font-semibold text-white'>prospa</h2>
                        </div>
                        <div className='fadeInB slider py-1'>
                            <Slider></Slider>
                        </div>
                        <div className='fadeInC subtext text-white'>
                            <h2 className='font-normal text-xl'>Create multiple sub-account</h2>
                            <p className='text-sm font-extralight'>Organise your business finances easily with multiple accounts. No limits!</p>
                        </div>
                    </div>
                    <div className='px-4 sm:px-20 overflow-hidden'>
                        <TopBar where='login' text='Sign In' prevStep={this.prevStep} showStepB={this.state.showStepB}></TopBar>
                        {/* form step a */}
                        {this.state.showStepA && <div
                            className={`signup-form xl:w-2/5 mx-auto py-10 slideInRight ${this.state.formClassName}`}
                            style={{ transition: 'opacity 1s cubic-bezier(0.16, 1, 0.3, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1)' }}>
                            <div className='title'>
                                <h2 className='text-28 font-semibold text-purple-base'>Create your account</h2>
                                <p className='text-light text-gray-light text-16'>A short description about account types</p>
                            </div>
                            <div className='form-body py-6 space-y-6'>
                                <TextInput placeholder='First name'></TextInput>
                                <TextInput placeholder='Last name'></TextInput>
                                <div className='grid grid-cols-12 gap-x-3'>
                                    <div className='col-span-4 sm:col-span-3'>
                                        <Dropdown ></Dropdown>
                                    </div>
                                    <div className='col-span-8 sm:col-span-9'>
                                        <TextInput placeholder='Mobile number'></TextInput>
                                    </div>
                                </div>
                                <TextInput placeholder='Email Address'></TextInput>
                                <button onClick={this.nextStep} className='w-full bg-violet text-16 text-white rounded py-3 focus:outline-none'>Next</button>
                            </div>
                        </div>}
                        {/* form step b */}
                        {this.state.showStepB && <div
                            className={`signup-form xl:w-2/5 mx-auto py-10 space-y-6 slideInLeft ${this.state.formClassNameB}`}
                            style={{ transition: 'opacity 1s cubic-bezier(0.16, 1, 0.3, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1)' }}>
                            <div className='title'>
                                <h2 className='text-24 font-semibold text-purple-base'>Open a new business account</h2>
                                <p className='text-light text-gray-light text-16'>A short description about account types</p>
                            </div>
                            <div className='body space-y-6'>
                                <List
                                    title="I have a registered business / charity with CAC"
                                    subtext='As a registered business you’ll get:'
                                    isList={true}
                                    list={{ a: 'Account in your business name', b: 'Send to and receive transfers from all Nigerian banks', c: 'Tools for business management' }}></List>
                                <List
                                    title="My business is not yet registered, I would like to register"
                                    subtext='Everything you need to start your business'
                                    isList={true}
                                    list={{ a: 'Registered business name with the CAC', b: 'Tax identification number', c: 'Your account will be automatically opened on completion' }}></List>
                                <List
                                    title="I’m a freelancer I do business in my personal name"
                                ></List>
                                <div>
                                    <Link to='/login'><button className='w-full bg-violet text-16 text-white rounded py-3 focus:outline-none'>Next</button></Link>
                                </div>
                            </div>

                        </div>}
                    </div>
                </div>
            </div >
        );
    }
}

export default SignUp;
