function Bell() {
    return (
        <div className='p-3 rounded-lg border-2'>
            <svg className='mx-auto' width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4872 6.97436V11.0769L13.1282 12.7179V13.5385H0V12.7179L1.64103 11.0769V6.97436C1.64103 4.44718 2.97846 2.34667 5.33333 1.78872V1.23077C5.33333 0.549744 5.88308 0 6.5641 0C7.24513 0 7.79487 0.549744 7.79487 1.23077V1.78872C10.1415 2.34667 11.4872 4.45538 11.4872 6.97436ZM8.20513 14.359C8.20513 15.2615 7.46667 16 6.5641 16C5.65333 16 4.92308 15.2615 4.92308 14.359H8.20513Z" fill="#8397AB" />
            </svg>
        </div>
    )
}

export default Bell