function Internet() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#17ECD4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 6.63333L18.3778 7.25556C17.4444 6.4 16.2778 5.93333 15.1111 5.93333C13.9444 5.93333 12.7778 6.4 11.8444 7.25556L11.2222 6.63333C12.3111 5.54444 13.7111 5 15.1111 5C16.5111 5 17.9111 5.54444 19 6.63333ZM15.1111 6.78889C16.0444 6.78889 16.9778 7.17778 17.6778 7.87778L17.0556 8.5C16.5111 7.95556 15.8111 7.72222 15.1111 7.72222C14.4111 7.72222 13.7111 7.95556 13.1667 8.5L12.5444 7.87778C13.2444 7.17778 14.1778 6.78889 15.1111 6.78889ZM17.4444 12.7778H15.8889V9.66667H14.3333V12.7778H6.55556C5.7 12.7778 5 13.4778 5 14.3333V17.4444C5 18.3 5.7 19 6.55556 19H17.4444C18.3 19 19 18.3 19 17.4444V14.3333C19 13.4778 18.3 12.7778 17.4444 12.7778ZM7.33333 16.6667H8.88889V15.1111H7.33333V16.6667ZM11.6111 16.6667H10.0556V15.1111H11.6111V16.6667ZM12.7778 16.6667H14.3333V15.1111H12.7778V16.6667Z" fill="white" />
            </svg>
        </div>
    )
}

export default Internet