import React from 'react'
import {
    Link
} from "react-router-dom";
import TopBar from './layout/SignUp/TopBar'
import Slider from './layout/Slider'
import TextInput from './elements/TextInput'
import '../assets/css/animate.css';
import safe from '../assets/img/safe.png'
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = { formClassName: 'opacity-1', formClassNameB: 'opacity-0', showStepA: true, showStepB: false }
        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
    }
    prevStep() {
        this.setState({ formClassNameB: 'transformCustomB opacity-0' })
        setTimeout(() => {
            this.setState({ showStepA: true, showStepB: false })
            this.setState({ formClassName: 'opacity-1' })
        }, 300)
    }
    nextStep() {
        this.setState({ formClassName: 'transformCustom opacity-0' })
        setTimeout(() => {
            this.setState({ showStepA: false, showStepB: true })
            this.setState({ formClassNameB: 'opacity-1' })
        }, 300)
    }
    render() {
        return (
            <div className="signup sm:grid grid-cols-12 h-screen">
                <div className="hidden sm:grid sidebar col-span-3 bg-purple-base py-4 px-8 ">
                    <div className=''>
                        <div className='fadeInA logo py-3'>
                            <h2 className='text-3xl font-semibold text-white'>prospa</h2>
                        </div>
                        <div className='fadeInB slider py-6'>
                            <Slider></Slider>
                        </div>
                        <div className='fadeInC subtext text-white'>
                            <h2 className='font-semibold sm:text-xl lg:text-3xl xl:text-32'>Create multiple <br /> sub-account</h2>
                            <p className='sm:text-xs sm:font-extralight lg:text-normal xl:text-14 xl:font-light'>Organise your business finances easily with multiple accounts. No limits!</p>
                        </div>
                    </div>
                    <div className='footer text-white text-14 self-end space-y-52'>
                        <div className='fadeInD'>
                            <img className='mx-auto' src={safe} />
                        </div>
                        <p className='fadeInE'>© 2020 Prospa Inc</p>
                    </div>
                </div>
                <div className="main-content col-span-9 ">
                    {/** sidebar view for mobile */}
                    <div className='block sm:hidden bg-purple-base px-4 py-3 space-y-4'>
                        <div className='fadeInA logo'>
                            <h2 className='text-3xl font-semibold text-white'>prospa</h2>
                        </div>
                        <div className='fadeInB slider py-1'>
                            <Slider></Slider>
                        </div>
                        <div className='fadeInC subtext text-white'>
                            <h2 className='font-normal text-xl'>Create multiple sub-account</h2>
                            <p className='text-sm font-extralight'>Organise your business finances easily with multiple accounts. No limits!</p>
                        </div>
                    </div>
                    <div className='px-4 sm:px-20 overflow-hidden'>
                        <TopBar where='signup' text='Sign Up' prevStep={this.prevStep} showStepB={this.state.showStepB}></TopBar>
                        <div
                            className={`signup-form xl:w-2/5 mx-auto py-10 slideInRight ${this.state.formClassName}`}
                            style={{ transition: 'opacity 1s cubic-bezier(0.16, 1, 0.3, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1)' }}>
                            <div className='title'>
                                <h2 className='text-28 font-semibold text-purple-base'>Welcome back to Prospa</h2>
                                <p className='text-light text-gray-light text-16'>An account, with powerful, personalised tools all in one place</p>
                            </div>
                            <div className='form-body py-6 space-y-6'>
                                <TextInput placeholder='Email address'></TextInput>
                                <TextInput placeholder='Password'></TextInput>
                                <div>
                                    <Link to='dashboard'><button className='w-full bg-violet text-16 text-white rounded py-3 focus:outline-none'>Next</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Login;
